import Axios from '../axios'
import BaseUrl from '../base'
//17.3.对接金融项目分析  按服务项目进行汇总
export function serviceColumn(data) {
    return Axios({
        method: 'post',
        url: BaseUrl.cs + '/radarOthers/list_servicecolumn/?showCount=-1&currentPage=1',
        params: data
    })
}
//17.3.对接金融项目分析  按按产品名称进行汇总
export function productname(data) {
    return Axios({
        method: 'post',
        url: BaseUrl.cs + '/radarOthers/list_productname/?showCount=-1&currentPage=1',
        params: data
    })
}
//17.3.对接金融项目分析  按按机构名称进行汇总
export function bankname(data) {
    return Axios({
        method: 'post',
        url: BaseUrl.cs + '/radarOthers/list_bankname/?showCount=-1&currentPage=1',
        params: data
    })
}

//17.4.服务处理状态分析
export function listStatus(data) {
    return Axios({
        method: 'post',
        url: BaseUrl.cs + '/radarOthers/list_status/?showCount=-1&currentPage=1',
        params: data
    })
}
//19.5.融资趋势分析
export function governmentTrend(data) {
    return Axios({
        method: 'post',
        url: BaseUrl.cs + '/radarOthers/list_government_trend',
        params: data
    })
}
// 企业综合分析 /radar/list/?showCount=-1&currentPage=1
export function  randerList(data) {
    return Axios({
        method: 'post',
        url: BaseUrl.cs + '/radar/list?showCount=100&currentPage=1',
        params: data
    })
}
// 企业综合分析2 /radarb/list/?showCount=-1&currentPage=1
export function  randerbList(data) {
    return Axios({
        method: 'post',
        url: BaseUrl.cs + '/radarb/list?showCount=100&currentPage=1',
        params: data
    })
}
