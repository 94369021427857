<template>
    <div  :style="{height:height,width:width}" ></div>
</template>

<script>
    import * as echarts from "echarts";
    import 'echarts-liquidfill/src/liquidFill.js'
    require("echarts/theme/macarons"); // echarts theme

    export default {
        name: 'Water',
        props: {
            dataVo:{
                type:Array,
                default: function () {
                    return []
                }
            },
            className: {
                type: String,
                default: 'chart'
            },
            width: {
                type: String,
                default: '670px'
            },
            height: {
                type: String,
                default: '515px'
            }
        },
        watch :{
            dataVo:function(val){
                console.log(JSON.parse(JSON.stringify(val)),'WATER')
                if(JSON.parse(JSON.stringify(val)).length == 0 ){
                    return false
                }
                val.map((item,index)=>{
                    item.value = item.COUNT
                    item.name = item.CNAME
                })
                this.serverDataVo = val
                this.initChart()
            },
        },
        data() {
            return {
                serverDataVo:[],
                chart: null
            }
        },
        mounted() {
            this.chart = echarts.init(this.$el, 'macarons')
            this.$nextTick(() => {
                // this.initChart()
            })
        },
        beforeDestroy() {
            if (!this.chart) {
                return
            }
            this.chart.dispose()
            this.chart = null
        },
        methods: {
            initChart() {
                console.log(this.serverDataVo[0],this.serverDataVo[0].COUNT/100)
                var arr={};
                arr.warterId = 'water_echarts_hd';
                arr.data=[
                    {"newdata":"5555","toldata":"10000"}
                ];
                var toldata = 100;//该水滴的总数据
                this.chart.setOption({
                    series: [{
                        type: 'liquidFill',
                        data:   [parseFloat(this.serverDataVo[0].COUNT/(this.serverDataVo[0].COUNT+this.serverDataVo[1].COUNT))],
                        radius: '70%',
                        center: ['50%', '50%'],//所在位置
                        label: {
                            normal: {
                                // formatter: value * 100 + '%',//显示所有小数位
                                formatter: function (val) {
                                    return '已完成 \n'  + (val.data*toldata).toFixed(2)+'%';
                                },
                                textStyle: {
                                    fontSize: 32, //字体大小
                                    color: '#fff',
                                    // insideColor: "red",
                                }
                            }
                        },
                        outline: {
                            show: true//边框
                        }
                        // waveAnimation: false, // 禁止左右波动
                    }]
                })
            }
        }
    }
</script>
