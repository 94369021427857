<template>
  <div class="main-box">
    <div class="wh-top">
      <div class="items" style="width: 18%;" v-if="loginType === 'zhengfu'">
        <div class="item">
          <p class="itema">
            <img
              src="../assets/images/zf1.png"
              style="width: 24px;height: 24px;margin-right: 10px;"
            />企业数
          </p>
          <p class="itemb">
            <span>{{parseFloat(USER_COUNT.ERTER_COUNT || 0).toFixed(0)}}</span>家
          </p>
        </div>
      </div>
      <div class="items" style="width: 18%;" v-if="loginType === 'jigou'">
        <div class="item">
          <p class="itema">
            <img
              src="../assets/images/zf2.png"
              style="width: 24px;height: 24px;margin-right: 10px;"
            />企业数
          </p>
          <p class="itemb">
            <!-- 李建朝 -->
            <!-- <span>{{parseFloat(USER_COUNT.BANK_COUNT || 0).toFixed(0)}}</span>家 -->
            <span>{{parseFloat(USER_COUNT.ERTER_COUNT || 0).toFixed(0)}}</span>家
          </p>
        </div>
      </div>
      <div class="items" style="width: 18%;" v-if="loginType === 'zhengfu'">
        <div class="item">
          <p class="itema">
            <img
              src="../assets/images/zf3.png"
              style="width: 24px;height: 24px;margin-right: 10px;"
            />金融机构数
          </p>
          <p class="itemb">
            <span>{{parseFloat(USER_COUNT.PROD_COUNT || 0).toFixed(0)}}</span>款
          </p>
        </div>
      </div>
      <div class="items" style="width: 18%;" v-if="loginType === 'zhengfu'">
        <div class="item">
          <p class="itema">
            <img
              src="../assets/images/zf4.png"
              style="width: 24px;height: 24px;margin-right: 10px;"
            />融资需求
          </p>
          <p class="itemb">
            <span>{{parseFloat(USER_COUNT.DEMAND_SUM || 0).toFixed(0)}}</span>万
          </p>
        </div>
      </div>
      <div class="items" style="width: 23%" v-if="loginType === 'qiye' || loginType === 'jigou'">
        <div class="item">
          <p class="itema">
            <img
              src="../assets/images/jg1.png"
              style="width: 24px;height: 24px;margin-right: 10px;"
            />评审额度
          </p>
          <p class="itemb">
            <span>{{parseFloat(USER_COUNT.DEMAND_COUNT || 0).toFixed(0)}}</span>个
          </p>
        </div>
      </div>

      <div class="items" style="width: 23%" v-if="loginType === 'jigou'">
        <div class="item">
          <p class="itema">
            <img
              src="../assets/images/jg3.png"
              style="width: 24px;height: 24px;margin-right: 10px;"
            />需求额度
          </p>
          <p class="itemb">
            <span>{{parseFloat(USER_COUNT.DEMAND_SUM || 0).toFixed(0)}}</span>万
          </p>
        </div>
      </div>
      <div class="items" style="width: 23%" v-if="loginType === 'qiye' || loginType === 'jigou'">
        <div class="item">
          <p class="itema">
            <img
              src="../assets/images/jg2.png"
              style="width: 24px;height: 24px;margin-right: 10px;"
            />成功对接
          </p>
          <p class="itemb">
            <span>{{parseFloat(USER_COUNT.CONTACT_SUCCESS_COUNT || 0).toFixed(0)}}</span>个
          </p>
        </div>
      </div>
      <div
        class="items"
        :style="{ width: loginType === 'jigou' ? '18%' : loginType === 'qiye' ? '23%' : '' }"
        v-if="loginType === 'qiye'"
      >
        <div class="item">
          <p class="itema">
            <img
              src="../assets/images/jg3.png"
              style="width: 24px;height: 24px;margin-right: 10px;"
            />需求额度
          </p>
          <p class="itemb">
            <span>{{parseFloat(USER_COUNT.DEMAND_SUM || 0).toFixed(0)}}</span>万
          </p>
        </div>
      </div>
      <div class="items" :style="{ width: loginType === 'zhengfu' ? '18%' : '23%' }">
        <div class="item">
          <p class="itema">
            <img
              src="../assets/images/jg4.png"
              style="width: 24px;height: 24px;margin-right: 10px;"
              v-if="loginType !== 'zhengfu'"
            />
            <img
              src="../assets/images/zf5.png"
              style="width: 24px;height: 24px;margin-right: 10px;"
              v-if="loginType === 'zhengfu'"
            />放款额度
          </p>
          <p class="itemb">
            <span>{{parseFloat(USER_COUNT.CONTACT_SUM || 0).toFixed(0) }}</span>万
          </p>
        </div>
      </div>
    </div>
    <div class="search-box">
      <div class="search-top" :gutter="24">
        <!--                <p>时间查询</p>-->
        <div>
          <!--                    <span class="sp-active">累计</span>-->
          <!--                    <span>今日</span>-->
          <!--                    <span>本周</span>-->
          <!--                    <span>本月</span>-->
          <!--                    <span>本年</span>-->
        </div>
        <div style="display: flex;align-items: center">
          <a-range-picker @change="onChange" />
          <div class="but-box" @click="confirmSearch">
            <a-button>确认查询</a-button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loginType == 'qiye'">
      <div class="wh-con">
        <div class="wh-con-item">
          <div class="title">
            <p>对接金融项目分析</p>
            <div class="t-right">
              <span class="dot" :class="serverIndex == 0 ? 'spactive' : ''" @click="jrxmfx(0)">服务类别</span>
              <span class="dot" :class="serverIndex == 1 ? 'spactive' : ''" @click="jrxmfx(1)">产品类别</span>
              <span class="dot" :class="serverIndex == 2 ? 'spactive' : ''" @click="jrxmfx(2)">金融机构</span>
            </div>
          </div>
          <div class="content">
            <Core :dataVo="serverDataVo"></Core>
          </div>
        </div>
        <div class="wh-con-item">
          <div class="title">
            <p>服务处理状态分析</p>
            <div class="t-right">
              <span class="dot">
                <a-badge color="#da5858" />已拒绝
              </span>
              <span class="dot">
                <a-badge color="#6699ff" />已申请
              </span>
              <span class="dot">
                <a-badge color="#7865c5" />已完成
              </span>
            </div>
          </div>
          <div class="content">
            <Pie :dataVo="pieDataVo"></Pie>
            <!--                        <img src="../assets/images/whitem04.png" alt="">-->
          </div>
        </div>
      </div>
      <div class="wh-cons">
        <div class="wh-con-item">
          <div class="title">
            <p>融资趋势分析</p>
            <div class="t-right">
              <span class="dot">
                <a-badge color="#435fcd" />需求金额
              </span>
              <span class="dot">
                <a-badge color="#7865c5" />对接金额
              </span>
              <span class="dot">
                <a-badge color="#ff9600" />对接成功率
              </span>
            </div>
          </div>
          <div class="content">
            <Bar1 :dataVo="barDataVo"></Bar1>
            <!--                        <img src="../assets/images/whitem05.png" alt="">-->
          </div>
        </div>
      </div>
      <div class="wh-con">
        <div class="wh-con-item">
          <div class="title">
            <p>企业综合分析</p>
            <div class="t-right">
              <span class="dot">
                <a-badge color="#435fcd" />本期
              </span>
              <span class="dot">
                <a-badge color="#7865c5" />上期
              </span>
            </div>
          </div>
          <div class="content">
            <Ld :data-vo="ld1DataVo"></Ld>
          </div>
        </div>
        <div class="wh-con-item">
          <div class="title">
            <p>申请项目按机构分析</p>
            <div class="t-right">
              <span class="dot">
                <a-badge color="#435fcd" />本期
              </span>
              <span class="dot">
                <a-badge color="#7865c5" />上期
              </span>
            </div>
          </div>
          <div class="content">
            <Ld :data-vo="ld2DataVo"></Ld>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loginType == 'jigou'">
      <div class="wh-con">
        <div class="wh-con-item" style="width: 470px;height: 470px">
          <div class="title">
            <p>产品分析</p>
            <div class="t-right">
              <span class="dot" :class="serverIndex == 0 ? 'spactive' : ''" @click="cpfx(0)">放款额度</span>
              <span class="dot" :class="serverIndex == 1 ? 'spactive' : ''" @click="cpfx(1)">放款数量</span>
            </div>
          </div>
          <div class="content">
            <Core
              :name="valueName"
              :dataVo="serverDataVo"
              :width="'400px'"
              :height="'380px'"
              :radius="[20,80]"
            ></Core>
          </div>
        </div>
        <div class="wh-con-item" style="width: 470px;height: 470px">
          <div class="title">
            <p>行业分析</p>
            <!--                        <div class="t-right">-->
            <!--                            <span class="dot" :class="serverIndex == 0 ? 'spactive' : ''" @click="jrxmfx(0)">放款额度</span>-->
            <!--                            <span class="dot" :class="serverIndex == 1 ? 'spactive' : ''" @click="jrxmfx(1)">放款数量</span>-->
            <!--                        </div>-->
          </div>
          <div class="content">
            <Huan :width="'400px'" :height="'380px'" :data-vo="huaDataVo"></Huan>
          </div>
        </div>
        <div class="wh-con-item" style="width: 470px;height: 470px">
          <div class="title">
            <p>任务分析</p>
          </div>
          <div class="content">
            <Water :data-vo="rwfxVo" :width="'400px'" :height="'380px'"></Water>
            <!--                        <img src="../assets/images/whitem04.png" alt="">-->
          </div>
        </div>
      </div>
      <div class="wh-cons">
        <div class="wh-con-item">
          <div class="title">
            <p>产品统计排名</p>
          </div>
          <div class="content">
            <VerBar :dataVo="cppmVo"></VerBar>
            <!--                        <img src="../assets/images/whitem05.png" alt="">-->
          </div>
        </div>
      </div>

      <div class="wh-cons">
        <div class="wh-con-item">
          <div class="title">
            <p>融资趋势分析</p>
            <div class="t-right">
              <span class="dot">
                <a-badge color="#435fcd" />需求金额
              </span>
              <span class="dot">
                <a-badge color="#7865c5" />对接金额
              </span>
              <span class="dot">
                <a-badge color="#ff9600" />对接成功率
              </span>
            </div>
          </div>
          <div class="content">
            <Bar1 :dataVo="RZXQFXVo"></Bar1>
            <!--                        <img src="../assets/images/whitem05.png" alt="">-->
          </div>
        </div>
      </div>
      <div class="wh-cons">
        <div class="wh-con-item">
          <div class="title">
            <p>机构成效对比</p>
          </div>
          <div class="content">
            <Bar2 :dataVo="jgcxdbVo"></Bar2>
            <!--                        <img src="../assets/images/whitem05.png" alt="">-->
          </div>
        </div>
      </div>
      <div class="wh-cons">
        <div class="wh-con-item">
          <div class="title">
            <p>任务处理分析</p>
          </div>
          <div class="content">
            <Bar3 :dataVo="rwclfxVo"></Bar3>
            <!--                        <img src="../assets/images/whitem05.png" alt="">-->
          </div>
        </div>
      </div>
    </div>
    <div v-if="loginType == 'zhengfu'">
      <div class="wh-con">
        <div class="wh-con-item">
          <div class="title">
            <p>企业分析</p>
            <div class="t-right">
              <span class="dot" :class="serverIndex == 0 ? 'spactive' : ''">企业行业分析</span>
            </div>
          </div>
          <div class="content">
            <Pie2 :dataVo="qyfxVo"></Pie2>
          </div>
        </div>
        <div class="wh-con-item">
          <div class="title">
            <p>金融服务分析</p>
          </div>
          <div class="content">
            <Circle :data-vo="qyjrfwVo"></Circle>
          </div>
        </div>
      </div>
      <div class="wh-con">
        <div class="wh-con-item">
          <div class="title">
            <p>银行排行统计</p>
            <div class="t-right">
              <!--                            <span class="dot" :class="serverIndex == 0 ? 'spactive' : ''">行业类别</span>-->
            </div>
          </div>
          <div class="content">
            <VerBar1 :width="'660px'" :height="'380px'" :dataVo="yhphVo"></VerBar1>
          </div>
        </div>
        <div class="wh-con-item">
          <div class="title">
            <p>企业规模分析</p>
          </div>
          <div class="content">
            <VerBar2 :width="'660px'" :height="'380px'" :data-vo="cpphVo"></VerBar2>
          </div>
        </div>
      </div>

      <div class="wh-cons">
        <div class="wh-con-item">
          <div class="title">
            <p>融资趋势分析</p>
            <div class="t-right"></div>
          </div>
          <div class="content">
            <bar1 :dataVo="rzqsVo"></bar1>
            <!--                        <img src="../assets/images/whitem05.png" alt="">-->
          </div>
        </div>
      </div>
      <div class="wh-cons">
        <div class="wh-con-item">
          <div class="title">
            <p>任务处理分析</p>
          </div>
          <div class="content">
            <Bar3 :dataVo="rwclfxVo"></Bar3>
            <!--                        <img src="../assets/images/whitem05.png" alt="">-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  serviceColumn,
  productname,
  bankname,
  listStatus,
  governmentTrend,
  randerList,
  randerbList
} from "@/api/xmfx/qy";
import {
  govEnterprisetypecount,
  govServicecolumn,
  govTrend,
  govSuccesscount,
  govBankcount,
  govProductname
} from "@/api/xmfx/zf";
import {
  listBankProduct,
  listBankAgentcount,
  listBankEnterprisetypecount,
  listBankStatuscount,
  listBankMoneyProductdesc,
  listBankTrend,
  listBankSuccess,
  listBankSuccesscount
} from "@/api/xmfx/jg";
import Core from "./components/QY/Core";
import Pie from "./components/QY/Pie";
import Pie2 from "./components/QY/Pie2";
import Bar1 from "./components/QY/Bar1";
import Bar2 from "./components/QY/Bar2";
import Bar3 from "./components/QY/Bar3";
import Ld from "./components/QY/Ld";
import Huan from "./components/QY/Huan";
import Circle from "./components/QY/Circle";
import Water from "./components/QY/Water";
import VerBar from "./components/QY/VerBar";
import VerBar1 from "./components/QY/VerBar1";
import VerBar2 from "./components/QY/VerBar2";
export default {
  computed: {
    ...mapGetters(["loginType", "orgId", "userId"])
  },
  components: {
    Core,
    Pie,
    Bar1,
    Ld,
    Huan,
    Water,
    VerBar,
    Bar2,
    Bar3,
    Pie2,
    Circle,
    VerBar1,
    VerBar2
  },
  data() {
    return {
      ld2DataVo: [],
      rzqsVo: [],
      yhphVo: [],
      cpphVo: [],
      qyfxVo: [],
      qyjrfwVo: [],
      rwclfxVo: [],
      jgcxdbVo: [],
      cppmVo: [],
      RZXQFXVo: [],
      pieDataVo: [],
      rwfxVo: [],
      huaDataVo: [],
      ld1DataVo: [],
      barDataVo: [],
      serverIndex: 0,
      valueName: "DMONEY",
      serverDataVo: [],
      loginitems: {
        backgroundImage:
          "url(" + require("../assets/images/login-itemsa.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%"
      },
      USER_COUNT: "",
      lastStart: "",
      lastEnd: "",
      YEAR: new Date().getFullYear()
    };
  },
  methods: {
    //政府-企业分析
    getGovEnterprisetypecount() {
      var that = this;
      govEnterprisetypecount({
        dateStart: that.lastStart,
        dateEnd: that.lastEnd,
        tm: new Date().getTime()
      }).then(res => {
        if (res.result) {
          this.qyfxVo = res.varList;
        }
      });
    },
    //政府-金融服务分析
    getGovServicecolumn() {
      var that = this;
      govServicecolumn({
        dateStart: that.lastStart,
        dateEnd: that.lastEnd,
        tm: new Date().getTime()
      }).then(res => {
        if (res.result) {
          this.qyjrfwVo = res.varList;
        }
      });
    },
    //政府-融资趋势分析
    getGovTrend() {
      var that = this;
      govTrend({ YEAR: this.YEAR, tm: new Date().getTime() }).then(res => {
        if (res.result) {
          this.rzqsVo = res.varList;
        }
      });
    },
    //政府-任务处理分析
    getGovSuccesscount() {
      var that = this;
      govSuccesscount({ YEAR: this.YEAR, tm: new Date().getTime() }).then(
        res => {
          if (res.result) {
            this.rwclfxVo = res.varList;
          }
        }
      );
    },
    //政府-银行排行 govBankcount
    getGovBankcount() {
      var that = this;
      govBankcount({
        dateStart: that.lastStart,
        dateEnd: that.lastEnd,
        tm: new Date().getTime()
      }).then(res => {
        if (res.result) {
          this.yhphVo = res.varList;
        }
      });
    },
    //政府-产品排行 govBankcount
    getGovProductname() {
      var that = this;
      govProductname({
        dateStart: that.lastStart,
        dateEnd: that.lastEnd,
        tm: new Date().getTime()
      }).then(res => {
        if (res.result) {
          this.cpphVo = res.varList;
        }
      });
    },
    //任务处理分析
    getlistBankSuccesscount() {
      var that = this;
      listBankSuccesscount({
        BANKCHILD_ID: that.orgId,
        YEAR: this.YEAR,
        tm: new Date().getTime()
      }).then(res => {
        if (res.result) {
          this.rwclfxVo = res.varList;
        }
      });
    },
    //机构成效对比
    getJgcxdbVo() {
      var that = this;
      listBankSuccess({ tm: new Date().getTime() }).then(res => {
        if (res.result) {
          this.jgcxdbVo = res.varList;
        }
      });
    },
    //融资需求分析
    getRzxqfx() {
      var that = this;
      listBankTrend({
        BANKCHILD_ID: that.orgId,
        YEAR: this.YEAR,
        tm: new Date().getTime()
      }).then(res => {
        if (res.result) {
          this.RZXQFXVo = res.varList;
        }
      });
    },
    //产品排名
    getCppm() {
      var that = this;
      listBankMoneyProductdesc({
        BANKCHILD_ID: that.orgId,
        dateStart: that.lastStart,
        dateEnd: that.lastEnd,
        tm: new Date().getTime()
      }).then(res => {
        if (res.result) {
          this.cppmVo = res.varList;
        }
      });
    },

    //任务分析
    getRwfx() {
      var that = this;
      listBankStatuscount({
        BANKCHILD_ID: that.orgId,
        dateStart: that.lastStart,
        dateEnd: that.lastEnd,
        tm: new Date().getTime()
      }).then(res => {
        if (res.result) {
          this.rwfxVo = res.varList;
        }
      });
    },
    getHsync() {
      var that = this;
      listBankEnterprisetypecount({
        BANKCHILD_ID: that.orgId,
        dateStart: that.lastStart,
        dateEnd: that.lastEnd,
        tm: new Date().getTime()
      }).then(res => {
        console.log(res, "randerList");
        if (res.result) {
          this.huaDataVo = res.varList;
          // this.huaDataVo = [{AGENTCOUNT:10,CNAME: "制造业"},{AGENTCOUNT:100,CNAME: "手工业"},{AGENTCOUNT:45,CNAME: "订单业"},{AGENTCOUNT:22,CNAME: "版本业"}]
        }
      });
    },
    getRender1() {
      var that = this;
      randerList({ ENTERPRISE_ID: that.orgId, tm: new Date().getTime() }).then(
        res => {
          console.log(res, "randerList");
          if (res.result) {
            this.ld1DataVo = res.varList;
          }
        }
      );
    },
    getRender2() {
      var that = this;
      randerbList({ ENTERPRISE_ID: that.orgId, tm: new Date().getTime() }).then(
        res => {
          console.log(res, "randerBList=======");
          if (res.result) {
            this.ld2DataVo = res.varList;
          }
        }
      );
    },
    getBar() {
      var that = this;
      governmentTrend({
        ENTERPRISE_ID: that.orgId,
        YEAR: this.YEAR,
        tm: new Date().getTime()
      }).then(res => {
        if (res.result) {
          this.barDataVo = res.varList;
        }
      });
    },
    getPie() {
      var that = this;
      listStatus({
        ENTERPRISE_ID: that.orgId,
        lastStart: that.lastStart,
        lastEnd: that.lastEnd,
        tm: new Date().getTime()
      }).then(res => {
        if (res.result) {
          this.pieDataVo = res.varList;
        }
      });
    },
    confirmSearch() {
      this.getUserCount();
      if (this.loginType == "qiye") {
        this.jrxmfx(0);
        this.getPie();
        this.getBar();
        this.getRender1();
        this.getRender2();
      }
      if (this.loginType == "jigou") {
        this.cpfx(0);
        this.getHsync();
        this.getRwfx();
        this.getCppm();
        this.getRzxqfx();
        this.getJgcxdbVo();
        this.getlistBankSuccesscount();
      }

      if (this.loginType == "zhengfu") {
        this.getGovProductname();
        this.getGovEnterprisetypecount();
        this.getGovServicecolumn();
        this.getGovTrend();
        this.getGovSuccesscount();
        this.getGovBankcount();
      }
    },
    cpfx(index) {
      var that = this;
      this.serverIndex = index;
      switch (index) {
        case 0:
          listBankProduct({
            BANKCHILD_ID: that.orgId,
            dateStart: that.lastStart,
            dateEnd: that.lastEnd,
            tm: new Date().getTime()
          }).then(res => {
            console.log(res);
            if (res.result) {
              this.valueName = "DMONEY";
              this.serverDataVo = res.varList;
            }
          });
          break;
        case 1:
          listBankAgentcount({
            BANKCHILD_ID: that.orgId,
            dateStart: that.lastStart,
            dateEnd: that.lastEnd,
            tm: new Date().getTime()
          }).then(res => {
            console.log(res);
            if (res.result) {
              this.valueName = "AGENTCOUNT";
              this.serverDataVo = res.varList;
            }
          });
          break;
      }
    },
    jrxmfx(index) {
      var that = this;
      this.serverIndex = index;
      switch (index) {
        case 0:
          serviceColumn({
            ENTERPRISE_ID: that.orgId,
            lastStart: that.lastStart,
            lastEnd: that.lastEnd,
            tm: new Date().getTime()
          }).then(res => {
            console.log(res);
            if (res.result) {
              this.serverDataVo = res.varList;
            }
          });
          break;
        case 1:
          productname({
            ENTERPRISE_ID: that.orgId,
            lastStart: that.lastStart,
            lastEnd: that.lastEnd,
            tm: new Date().getTime()
          }).then(res => {
            console.log(res);
            if (res.result) {
              this.serverDataVo = res.varList;
            }
          });
          break;
        case 2:
          bankname({
            ENTERPRISE_ID: that.orgId,
            lastStart: that.lastStart,
            lastEnd: that.lastEnd,
            tm: new Date().getTime()
          }).then(res => {
            console.log(res);
            if (res.result) {
              this.serverDataVo = res.varList;
            }
          });
          break;
      }
    },
    onChange(date, dateString) {
      this.lastStart = dateString[0];
      this.lastEnd = dateString[1];
      console.log(date, dateString);
      this.YEAR = dateString[0].slice(0, 4);
    },
    // 获取用户数据统计
    getUserCount() {
      console.log(this.orgId, this.loginType);
      let params = {};
      if (this.loginType === "qiye") {
        params = { ENTERPRISE_ID: this.orgId, XCX_NAME:localStorage.getItem('xcxName') };
      } else if (this.loginType === "jigou") {
        params = { BANK_ID: this.orgId,XCX_NAME:localStorage.getItem('xcxName')  };
      }  else if (this.loginType === "zhengfu") {
        params = { GOVERMENT_ID: this.orgId,XCX_NAME:localStorage.getItem('xcxName') };
      }
      this.$store.dispatch("getUserOutLine", params).then(res => {
        if (res.result === "success") {
          this.USER_COUNT = res;
        } else {
          this.USER_COUNT = "";
        }
      });
    }
  },
  mounted() {
    this.getUserCount();
    console.log(this.orgId, "woshi irgId");
    if (this.orgId == "null") {
      console.log(12129999999);
      return false;
    }
    if (this.loginType == "qiye") {
      this.jrxmfx(0);
      this.getPie();
      this.getBar();
      this.getRender1();
      this.getRender2();
    }
    if (this.loginType == "jigou") {
      this.cpfx(0);
      this.getHsync();
      this.getRwfx();
      this.getCppm();
      this.getRzxqfx();
      this.getJgcxdbVo();
      this.getlistBankSuccesscount();
    }
    if (this.loginType == "zhengfu") {
      this.getGovProductname();
      this.getGovEnterprisetypecount();
      this.getGovServicecolumn();
      this.getGovTrend();
      this.getGovSuccesscount();
      this.getGovBankcount();
    }
  },
  created() {}
};
</script>

<style  lang="less" scoped>
.but-box ::v-deep(.ant-btn) {
  padding: 0 30px;
  color: #ffffff;
  height: 40px;
  border-radius: 5px;
  font-size: 16px;
  background: @color-blue; /* 标准语法 */
}
</style>
<style lang="less" scoped>
.main-box {
  width: @main-width-base;
  margin: 20px auto;
  .wh-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .items {
      padding: 8px;
      background-color: @color-ff;
      box-shadow: 0px 0px 10px #929292;
      border-radius: 5px;
      .item {
        padding: 20px;
        width: auto;
        height: 144px;
        background: url("../assets/images/login-itemsa.png") no-repeat 100% 100%;
        background-size: 100% 100%;
        p {
          margin-bottom: 0;
          color: #fff;
        }
        .itema {
          font-size: @font-xl;
          display: flex;
          align-items: center;
          span {
            margin-right: 10px;
            font-size: 24px;
          }
        }
        .itemb {
          font-size: @font-md;
          text-align: right;
          margin-top: 28px;
          height: 48px;
          line-height: 48px;
          span {
            font-size: 40px;
            font-weight: bold;
            margin-right: 4px;
            text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
          }
        }
      }
    }
  }
  .search-box {
    margin: 20px auto;
    .search-top {
      padding: 15px 30px;
      background-color: @color-ff;
      border-radius: 5px;
      box-shadow: 0px 0px 10px #929292;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        margin-bottom: 0;
        height: 40px;
        line-height: 40px;
        font-size: @font-lg;
        width: 180px;
      }
      div {
        span {
          font-size: 14px;
          padding: 8px 18px;
          margin: 0 10px;
          cursor: pointer;
        }
        .sp-active {
          background: @color-blue; /* 标准语法 */
          color: #fff;
        }
        span:hover {
          background: @color-blue; /* 标准语法 */
          color: #fff;
        }
      }
    }
    .search-top::before {
      content: "";
      position: absolute;
      left: -3px;
      top: 15px;
      width: 6px;
      height: 40px;
      background: @color-blue; /* 标准语法 */
    }
    .search-top::after {
      content: "";
      position: absolute;
      right: -3px;
      top: 15px;
      width: 6px;
      height: 40px;
      background: @color-blue; /* 标准语法 */
    }
  }
  .wh-con {
    display: flex;
    justify-content: space-between;
    margin: 20px auto;
    .wh-con-item {
      position: relative;
      padding: 20px;
      width: 710px;
      background-color: @color-ff;
      border-radius: 5px;
      box-shadow: 0px 0px 10px #929292;
      .title {
        font-size: @font-lg;
        padding: 0px 10px;
        padding-bottom: 15px;
        border-bottom: @border-base;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
          margin-bottom: 0;
        }
        .t-right {
          .dot {
            font-size: @font-lg;
            margin: 0 10px;
            padding: 0 10px;
          }
          .spactive {
            color: @color-blue;
            font-weight: bold;
          }
        }
      }
      .content {
        padding-top: 15px;
        img {
          width: 100%;
        }
      }
    }
    .wh-con-item::before {
      content: "";
      position: absolute;
      left: -3px;
      top: 10px;
      width: 6px;
      height: 40px;
      background: @color-blue; /* 标准语法 */
    }
  }
  .wh-cons {
    margin: 20px auto;
    .wh-con-item {
      padding: 20px;
      background-color: @color-ff;
      border-radius: 5px;
      box-shadow: 0px 0px 10px #929292;
      .title {
        font-size: @font-lg;
        padding: 0px 10px;
        padding-bottom: 15px;
        border-bottom: @border-base;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
          margin-bottom: 0;
        }
        .t-right {
          .dot {
            font-size: @font-lg;
            margin: 0 10px;
            padding: 0 10px;
          }
          .spactive {
            color: @color-blue;
            font-weight: bold;
          }
        }
      }
      .content {
        padding-top: 15px;
        img {
          width: 100%;
        }
      }
    }
  }
}
</style>
