<template>
        <div :class="className" :style="{height:height,width:width}" ></div>
</template>

<script>
    import * as echarts from "echarts";
    require("echarts/theme/macarons"); // echarts theme

    export default {
        props: {
            dataVo:{
                type:Array,
                default: function () {
                    return []
                }
            },
                radius:{
                        type:Array,
                        default: function () {
                                return [50, 200]
                        }
                },
            className: {
                type: String,
                default: 'chart'
            },
                name: {
                        type: String,
                        default: 'DMONEY'
                },
            width: {
                type: String,
                default: '670px'
            },
            height: {
                type: String,
                default: '515px'
            }
        },
        watch :{
                    dataVo:function(val){
                            if(val.length == 0){
                                    return false
                            }
                        console.log(val)
                        val.map((item,index)=>{
                            item.value = item[this.valueName]
                            item.name = item.CNAME
                        })
                        this.serverDataVo = val

                        this.initChart()
                    },
                name: function(val){
                        if(this.serverDataVo.length == 0){
                                return false
                        }
                        console.log(val)
                        this.valueName = val
                        this.serverDataVo.map((item,index)=>{
                                item.value = item[this.valueName]
                                item.name = item.CNAME
                        })
                        this.initChart()
                },
        },
        data() {
            return {
                    valueName:'DMONEY',
                serverDataVo:[],
                chart: null
            }
        },
        mounted() {
            this.chart = echarts.init(this.$el, 'macarons')
            this.$nextTick(() => {
                console.log('12121')
                this.initChart()
            })
        },
        beforeDestroy() {
            if (!this.chart) {
                return
            }
            this.chart.dispose()
            this.chart = null
        },
        methods: {
            initChart() {
                this.chart.setOption({
                        tooltip: {
                                trigger: 'item',
                                formatter: '{a} <br/>{b} : ({c}万) ({d}%)',
                                color:'#ffffff'
                        },
                    toolbox: {
                        show: true,
                        feature: {
                            mark: {show: true},
                            dataView: {show: true, readOnly: false},
                            restore: {show: true},
                            saveAsImage: {show: true}
                        }
                    },
                    series: [
                        {
                            name: '产品分析',
                            type: 'pie',
                            radius:this.radius,
                            center: ['50%', '50%'],
                            roseType: 'area',
                            itemStyle: {
                                borderRadius: 3
                            },
                            data: this.serverDataVo
                        }
                    ]
                })
                    setTimeout(function (){
                            window.onresize = function () {
                                    this.chart.resize();
                            }
                    },200)
            }
        }
    }
</script>
