<template>
        <div :class="className" :style="{height:height,width:width}" ></div>
</template>

<script>
    import * as echarts from "echarts";
    require("echarts/theme/macarons"); // echarts theme

    export default {
        props: {
            dataVo:{
                type:Array,
                default: function () {
                    return []
                }
            },
            className: {
                type: String,
                default: 'chart'
            },
            width: {
                type: String,
                default: '1400px'
            },
            height: {
                type: String,
                default: '530px'
            }
        },
        watch :{
        dataVo:function(val){
                if(val.length == 0){
                        return false
                }
                console.log(JSON.parse(JSON.stringify(val)))
                val.map((item,index)=>{
                        this.a.unshift(item.RATE)
                        this.c.unshift(item.CNAME)
                        this.b.unshift(item.DMONEY)
                })
                this.initChart()
        },
        },
        data() {
            return {
                serverDataVo:[],
                    a:[],
                    b:[],
                    c:[],
                chart: null
            }
        },
        mounted() {
            this.chart = echarts.init(this.$el, 'macarons')
            this.$nextTick(() => {
                console.log('12121')
                this.initChart()
            })
        },
        beforeDestroy() {
            if (!this.chart) {
                return
            }
            this.chart.dispose()
            this.chart = null
        },
        methods: {
                getList(list,dataName){
                        console.log(list)
                        var a = []
                        for(var i =0; i<=11;i++ ){
                                console.log(list['M0'+(i+1)])
                                if (i+1 <10){
                                        a.push(list['M0'+(i+1)])
                                }else{
                                        a.push(list['M'+(i+1)])
                                }
                        }
                        console.log(a)
                        this[dataName] = a
                },
            initChart() {
                this.chart.setOption({

                        tooltip: {
                                trigger: 'axis'
                        },
                        legend: {
                                data: ['额度', '成功率']
                        },
                        toolbox: {
                                show: true,
                                feature: {
                                        dataView: {show: true, readOnly: false},
                                        magicType: {show: true, type: ['line', 'bar']},
                                        restore: {show: true},
                                        saveAsImage: {show: true}
                                }
                        },
                        calculable: true,
                        xAxis: [
                                {
                                        type: 'category',
                                        data:this.c
                                }
                        ],
                        yAxis: [
                                {
                                        type: 'value'
                                }
                        ],
                        series: [
                                {
                                        name: '额度',
                                        type: 'bar',
                                        data: this.b,
                                        markPoint: {
                                                data: [
                                                        {type: 'max', name: '最大值'},
                                                ]
                                        },
                                        markLine: {
                                                data: [
                                                        {type: 'average', name: '平均值'}
                                                ]
                                        }
                                },
                                {
                                        name: '成功率',
                                        type: 'line',
                                        data: this.a,
                                        markLine: {
                                                data: [
                                                        {type: 'average', name: '平均值'}
                                                ]
                                        }
                                }
                        ]
                })
            }
        }
    }
</script>
