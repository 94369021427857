<template>
    <div  :style="{height:height,width:width}" ></div>
</template>

<script>
    import * as echarts from "echarts";
    require("echarts/theme/macarons"); // echarts theme
    const unwarp = (obj) => obj && (obj.__v_raw || obj.valueOf() || obj);

    export default {
        name: 'Circle',
        props: {
            dataVo:{
                type:Array,
                default: function () {
                    return []
                }
            },
            className: {
                type: String,
                default: 'chart'
            },
            width: {
                type: String,
                default: '670px'
            },
            height: {
                type: String,
                default: '515px'
            }
        },
        watch :{
            dataVo:function(val){
                if(val.length == 0){
                    return false
                }
                console.log(val)
                val.map((item,index)=>{
                    item.value = item.COLCOUNT
                    item.name = item.CNAME
                })
                this.serverDataVo = val
                unwarp(this.chart).setOption(this.getOption());
            },
        },
        data() {
            return {
                serverDataVo:[],
                chart: null
            }
        },
        mounted() {
            this.chart = echarts.init(this.$el, 'macarons')
            this.$nextTick(() => {
                unwarp(this.chart).setOption(this.getOption());
            })
        },
        beforeDestroy() {
            if (!this.chart) {
                return
            }
            this.chart.dispose()
            this.chart = null
        },
        methods: {
            getOption() {
                return{
                    tooltip: {
                        trigger: 'item',
                        backgroundColor:'#ffffff',
                    },
                    legend: {
                        top: '5%',
                        left: 'center'
                    },
                    series: [
                        {
                            name: '访问来源',
                            type: 'pie',
                            radius: ['40%', '70%'],
                            avoidLabelOverlap: false,
                            itemStyle: {
                                borderRadius: 10,
                                borderColor: '#fff',
                                borderWidth: 2
                            },
                            label: {
                                show: false,
                                position: 'center'
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    fontSize: '40',
                                    fontWeight: 'bold'
                                }
                            },
                            labelLine: {
                                show: false
                            },
                            data: this.serverDataVo
                        }
                    ]

                }
            }
        }
    }
</script>
