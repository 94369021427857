import Axios from '../axios'
import BaseUrl from '../base'
//17.3.对接金融项目分析  按服务项目进行汇总
export function govEnterprisetypecount(data) {
    return Axios({
        method: 'post',
        url: BaseUrl.cs + '/radarOthers/list_government_enterprisetypecount',
        params: data
    })
}
// //19.2.金融服务分析
export function govServicecolumn(data) {
    return Axios({
        method: 'post',
        url: BaseUrl.cs + '/radarOthers/list_government_servicecolumn',
        params: data
    })
}
//19.3.银行排行统计
export function govBankcount(data) {
    return Axios({
        method: 'post',
        url: BaseUrl.cs + '/radarOthers/list_government_bankcount',
        params: data
    })
}
//19.4.产品排行统计
export function govProductname(data) {
    return Axios({
        method: 'post',
        url: BaseUrl.cs + '/radarOthers/list_government_productname',
        params: data
    })
}
//19.5.融资趋势分析
export function govTrend(data) {
    return Axios({
        method: 'post',
        url: BaseUrl.cs + '/radarOthers/list_government_trend',
        params: data
    })
}
//19.6.任务处理分析
export function govSuccesscount(data) {
    return Axios({
        method: 'post',
        url: BaseUrl.cs + '/radarOthers/list_government_successcount',
        params: data
    })
}
//
