<template>
    <div :class="className" :style="{height:height,width:width}" ></div>
</template>

<script>
    import * as echarts from "echarts";
    require("echarts/theme/macarons"); // echarts theme

    export default {
        name:'VerBar',
        props: {
            dataVo:{
                type:Array,
                default: function () {
                    return []
                }
            },
            className: {
                type: String,
                default: 'chart'
            },
            width: {
                type: String,
                default: '1400px'
            },
            height: {
                type: String,
                default: '530px'
            }
        },
        watch :{
            dataVo:function(val){
                if(val.length == 0){
                    return false
                }
                this.a = []
                this.b = []
                console.log(JSON.parse(JSON.stringify(val)))
                val.map((item,index)=>{
                    this.a.unshift(item.CNAME)
                    this.b.unshift(item.COLCOUNT)
                })
                this.initChart()
            },
        },
        data() {
            return {
                serverDataVo:[],
                a:[],
                b:[],
                c:[],
                chart: null
            }
        },
        mounted() {
            this.chart = echarts.init(this.$el, 'macarons')
            this.$nextTick(() => {
                console.log('12121')
                this.initChart()
            })
        },
        beforeDestroy() {
            if (!this.chart) {
                return
            }
            this.chart.dispose()
            this.chart = null
        },
        methods: {
            getList(list,dataName){
                console.log(list)
                var a = []
                for(var i =0; i<=11;i++ ){
                    console.log(list['M0'+(i+1)])
                    if (i+1 <10){
                        a.push(list['M0'+(i+1)])
                    }else{
                        a.push(list['M'+(i+1)])
                    }
                }
                console.log(a)
                this[dataName] = a
            },
            initChart() {
                this.chart.setOption({
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#6a7985'
                            }
                        }
                    },
                    legend: {
                        data: ['2011年', '2012年']
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'value',
                        boundaryGap: [0, 0.01]
                    },
                    yAxis: {
                        type: 'category',
                        data:this.a
                    },
                    series: [
                        {
                            label: {
                                show: true,
                                position: 'right'
                            },
                            name: '产品统计排名',
                            type: 'bar',
                            data: this.b
                        }
                    ]
                })
            }
        }
    }
</script>
