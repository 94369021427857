<template>
        <div :class="className" :style="{height:height,width:width}" ></div>
</template>

<script>
    import * as echarts from "echarts";
    require("echarts/theme/macarons"); // echarts theme

    export default {
        props: {
            dataVo:{
                type:Array,
                default: function () {
                    return []
                }
            },
            className: {
                type: String,
                default: 'chart'
            },
            width: {
                type: String,
                default: '670px'
            },
            height: {
                type: String,
                default: '515px'
            }
        },
        watch :{
            dataVo:function(val){
                    if(val.length == 0){
                            return false
                    }
                console.log(JSON.parse(JSON.stringify(val)))
                    var a = []
                    var b = []
                    var c = []
                    var d = []
                    var e = []
                    a.push(val[0])
                    console.log(a[0].QUARTER)
                    val.map((item,index)=>{
                                    if(val[index].QUARTER == a[0].QUARTER){
                                            a.push(item)
                                    }else{
                                            b.push(item)
                                            c.push({name:item.EVALINDI})
                                    }
                            })
                    a.splice(0,1)
                    a.map((item,index)=>{
                        d.push(item.SCORAVER)
                    })
                    b.map((item,index)=>{
                            e.push(item.SCORAVER)
                    })
                    console.log(a,b,c,'122121======')
                    this.a = a
                    this.b = b
                    this.c = c
                    this.d = d
                    this.e = e
                    this.$nextTick(() => {
                            this.initChart()
                    })
            },
        },
        data() {
            return {
                serverDataVo:[],
                chart: null,
                    a:[],
                    b:[],
                    d:[],
                    e:[]
            }
        },
        mounted() {
            this.chart = echarts.init(this.$el, 'macarons')
            this.$nextTick(() => {
                console.log('12121')
                // this.initChart()
            })
        },
        beforeDestroy() {
            if (!this.chart) {
                return
            }
            this.chart.dispose()
            this.chart = null
        },
        methods: {
            initChart() {
                    var that = this
                this.chart.setOption({
                        title: {
                                text: that.a[0].QUARTER+' vs'+  that.b[0].QUARTER
                        },
                        legend: {
                                data: [that.a[0].QUARTER, that.b[0].QUARTER]
                        },
                        radar: {
                                // shape: 'circle',
                                indicator: this.c
                        },
                        series: [{
                                name: that.a[0].QUARTER+' vs'+  that.b[0].QUARTER,
                                type: 'radar',
                                data: [
                                        {
                                                value: that.d,
                                                name: that.a[0].QUARTER
                                        },
                                        {
                                                value: that.e,
                                                name: that.b[0].QUARTER
                                        }
                                ]
                        }]
                })
            }
        }
    }
</script>
