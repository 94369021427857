<template>
        <div :class="className" :style="{height:height,width:width}" ></div>
</template>

<script>
    import * as echarts from "echarts";
    require("echarts/theme/macarons"); // echarts theme

    export default {
        props: {
            dataVo:{
                type:Array,
                default: function () {
                    return []
                }
            },
            className: {
                type: String,
                default: 'chart'
            },
            width: {
                type: String,
                default: '1400px'
            },
            height: {
                type: String,
                default: '530px'
            }
        },
        watch :{
            dataVo:function(val){
                //     console.log(JSON.parse(JSON.stringify(val)),'BAR11')

                    if(val.length == 0 || val[1] == undefined){
                            return false
                    }
                        var c = []
                    this.getList(val[0],'a')
                    this.getList(val[1],'b')
                        this.a.map((item,index)=>{
                                if (item == 0){
                                        c.push(0)
                                }else{
                                        c.push(parseFloat(((this.b[index]/item)*100).toFixed(2)))
                                }
                        })
                    this.c = c
                //     console.log(c)
                this.initChart()
            },
        },
        data() {
            return {
                serverDataVo:[],
                    a:[],
                    b:[],
                    c:[],
                chart: null
            }
        },
        mounted() {
            this.chart = echarts.init(this.$el, 'macarons')
            this.$nextTick(() => {
                // console.log('12121')
                this.initChart()
            })
        },
        beforeDestroy() {
            if (!this.chart) {
                return
            }
            this.chart.dispose()
            this.chart = null
        },
        methods: {
                getList(list,dataName){
                        // console.log(list)
                        var a = []
                        for(var i =0; i<=11;i++ ){
                                // console.log(list['M0'+(i+1)])
                                if (i+1 <10){
                                        a.push(list['M0'+(i+1)])
                                }else{
                                        a.push(list['M'+(i+1)])
                                }
                        }
                        // console.log(a)
                        this[dataName] = a
                },
            initChart() {
                this.chart.setOption({
                        tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                        type: 'cross',
                                        crossStyle: {
                                                color: '#999'
                                        }
                                }
                        },
                        toolbox: {
                                feature: {
                                        dataView: {show: true, readOnly: false},
                                        magicType: {show: true, type: ['line', 'bar']},
                                        restore: {show: true},
                                        saveAsImage: {show: true}
                                }
                        },
                        legend: {
                                data: ['需求金额', '对接金额', '成功率']
                        },
                        xAxis: [
                                {
                                        type: 'category',
                                        data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
                                        axisPointer: {
                                                type: 'shadow'
                                        }
                                }
                        ],
                        yAxis: [
                                {
                                        type: 'value',
                                        name: '额度',
                                        axisLabel: {
                                                formatter: '{value} 万元'
                                        }
                                },
                                {
                                        type: 'value',
                                        name: '成功率',
                                        min: 0,
                                        max: 100,
                                        interval: 10,
                                        axisLabel: {
                                                formatter: '{value} %'
                                        }
                                }
                        ],
                        series: [
                                {
                                        name: '需求金额',
                                        type: 'bar',
                                        data:this.a
                                },
                                {
                                        name: '对接金额',
                                        type: 'bar',
                                        data: this.b
                                },
                                {
                                        name: '成功率',
                                        type: 'line',
                                        yAxisIndex: 1,
                                        data: this.c
                                }
                        ]
                })
            }
        }
    }
</script>
