import Axios from '../axios'
import BaseUrl from '../base'
//18.1.产品分析-放款额度
export function listBankProduct(data) {
    return Axios({
        method: 'post',
        url: BaseUrl.cs + '/radarOthers/list_bank_product/?showCount=-1&currentPage=1',
        params: data
    })
}
//信息修改
export function enterpriseAuthEdit(data) {
    return Axios({
        method: 'post',
        url: BaseUrl.cs + '/enterprise/edit',
        params: data
    })
}
//18.2.产品分析-放款数量
export function listBankAgentcount(data) {
    return Axios({
        method: 'post',
        url: BaseUrl.cs + '/radarOthers/list_bank_agentcount',
        params: data
    })
}
//18.3.行业分析
export function listBankEnterprisetypecount(data) {
    return Axios({
        method: 'post',
        url: BaseUrl.cs + '/radarOthers/list_bank_enterprisetypecount',
        params: data
    })
}
//18.4.任务分析
export function listBankStatuscount(data) {
    return Axios({
        method: 'post',
        url: BaseUrl.cs + '/radarOthers/list_bank_statuscount',
        params: data
    })
}
//18.5.产品排名
export function listBankMoneyProductdesc(data) {
    return Axios({
        method: 'post',
        url: BaseUrl.cs + '/radarOthers/list_bank_money_productdesc',
        params: data
    })
}
//18.6.融资趋势分析
export function listBankTrend(data) {
    return Axios({
        method: 'post',
        url: BaseUrl.cs + '/radarOthers/list_bank_trend',
        params: data
    })
}
//18.7.机构成效对比
export function listBankSuccess(data) {
    return Axios({
        method: 'post',
        url: BaseUrl.cs + '/radarOthers/list_bank_success/?showCount=-1&currentPage=1',
        params: data
    })
}
//18.8.任务处理分析
export function listBankSuccesscount(data) {
    return Axios({
        method: 'post',
        url: BaseUrl.cs + '/radarOthers/list_bank_successcount/?showCount=-1&currentPage=1',
        params: data
    })
}
