<template>
    <div :class="className" :style="{height:height,width:width}" ></div>
</template>

<script>
    import * as echarts from "echarts";
    require("echarts/theme/macarons"); // echarts theme

    export default {
        name:'Huan',
        props: {
            dataVo:{
                type:Array,
                default: function () {
                    return []
                }
            },
            radius:{
                type:Array,
                default: function () {
                    return [50, 200]
                }
            },
            className: {
                type: String,
                default: 'chart'
            },
            name: {
                type: String,
                default: 'DMONEY'
            },
            width: {
                type: String,
                default: '670px'
            },
            height: {
                type: String,
                default: '515px'
            }
        },
        watch :{

            dataVo:function(val){
                if(val.length == 0){
                    return false
                }
                console.log(val)
                var a = []
                var b = []
                val.map((item,index)=>{
                    item.value = item.AGENTCOUNT
                    item.name = item.CNAME
                    a.push(item.AGENTCOUNT)
                    b.push(item.CNAME)
                    this.series.push({
                        name:item.CNAME,
                        type:'pie',
                        radius: [60-index*10+'%', 65-index*10+'%'],
                        //环的位置
                        label: {
                            show: false,
                            position: 'center',
                            // normal: { // 显示的位置
                            //     position: 'inner'
                            // }
                        },
                        labelLine: {
                            normal: {
                                show: false
                            }
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '20',
                                fontWeight: 'bold'
                            }
                        },
                        data:[
                            {
                                value:item.AGENTCOUNT, //需要显示的数据
                                name: item.CNAME+item.AGENTCOUNT+'个',
                            },
                            {
                                value:this.total-item.AGENTCOUNT,
                                //不需要显示的数据，颜色设置成和背景一样
                                itemStyle: {
                                    normal: {
                                        color: 'transparent'
                                    }
                                }
                            }
                        ]
                    })

                })
                this.a = a
                this.b = b
                console.log(this.a,this.b)
                this.serverDataVo = val

                this.initChart()
            },
            name: function(val){
                if(this.serverDataVo.length == 0){
                    return false
                }
                console.log(val)
                this.valueName = val
                this.serverDataVo.map((item,index)=>{
                    item.value = item[this.valueName]
                    item.name = item.CNAME
                })
                this.initChart()
            },
        },
        data() {
            return {
                a:[],
                b:[],
                series:[],
                serverDataVo:[],
                chart: null,
               pass_rate:70,
                average: 60,
                total: 100

        }
        },
        mounted() {
            this.chart = echarts.init(this.$el, 'macarons')
            this.$nextTick(() => {
                console.log('12121')
                this.initChart()
            })
        },
        beforeDestroy() {
            if (!this.chart) {
                return
            }
            this.chart.dispose()
            this.chart = null
        },
        methods: {
            initChart() {
                this.chart.setOption({
                    tooltip: {
                        show:false,
                        trigger: 'item',
                        formatter: "{a} : {c} ({d}%)"
                    },
                    // color: ['rgb(255,159,127)', 'rgb(50,197,233)'],
                    legend: {
                        orient: 'horizontal',
                        itemGap:10,//图例之间的间距
                        data:this.b,
                        bottom:0
                        //图例的名字需要和饼图的name一致，才会显示图例
                    },
                    series: this.series
                })
            }
        }
    }
</script>
