<template>
    <div  :style="{height:height,width:width}" ></div>
</template>

<script>
    import * as echarts from "echarts";
    require("echarts/theme/macarons"); // echarts theme
    const unwarp = (obj) => obj && (obj.__v_raw || obj.valueOf() || obj);

    export default {
        name: 'Pie2',
        props: {
            dataVo:{
                type:Array,
                default: function () {
                    return []
                }
            },
            className: {
                type: String,
                default: 'chart'
            },
            width: {
                type: String,
                default: '670px'
            },
            height: {
                type: String,
                default: '515px'
            }
        },
        watch :{
            dataVo:function(val){
                if(val.length == 0){
                    return false
                }
                // console.log(val)
                val.map((item,index)=>{
                    item.value = item.AGENTCOUNT
                    item.name = item.CNAME
                })
                this.serverDataVo = val
                unwarp(this.chart).setOption(this.getOption());
            },
        },
        data() {
            return {
                serverDataVo:[],
                chart: null
            }
        },
        mounted() {
            this.chart = echarts.init(this.$el, 'macarons')
            this.$nextTick(() => {
                unwarp(this.chart).setOption(this.getOption());
            })
        },
        beforeDestroy() {
            if (!this.chart) {
                return
            }
            this.chart.dispose()
            this.chart = null
        },
        methods: {
            getOption() {
                return{
                    tooltip: {
                        trigger: 'item',
                        backgroundColor:'#ffffff',

                    },
                    series: [
                        {

                            type: 'pie',
                            radius: '80%',
                            data:this.serverDataVo,
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            }
                        }
                    ]

                }
            }
        }
    }
</script>
